<template>
	<div class="account_login">
		<div class="top">
			<loginHead></loginHead>
		</div>
		<div class="container">
			<div class="small_logo" @click="toHomePage">
				<img src="@/assets/login/small_logo.png">
			</div>
			<div class="main">
				<main>
					<div class="login_top">
						<div class="img"> <img src="@/assets/login/flag.png"></div>
						<div class="login_title">{{$t('title.bindingAccout')}}</div>
					</div>
					<div class="login_change">
						<div class="item" @click="curSelect=1">
							<div class="item_title" :style="{color:curSelect==1?'#E60012':'#999999'}">
								{{$t('set.bindingPhone') }}
							</div>
							<div class="img" v-if="curSelect==1">
								<img src="@/assets/login/select.png">
							</div>
						</div>
						<div class="item" @click="curSelect=2">
							<div class="item_title" :style="{color:curSelect==2?'#E60012':'#999999'}">
								{{$t('set.bindingEmail') }}
							</div>
							<div class="img" v-if="curSelect==2">
								<img src="@/assets/login/select.png">
							</div>
						</div>
					</div>
					<div class="login_input">
						<div class="input_phone" v-if="curSelect==1">
							<div class="input_img">
								<img src="../../assets/login/phone.png">
							</div>
							<div class="area_code">
								<el-dropdown @command="areaCodeClick">
									<span class="el-dropdown-link">
										{{areaCode}}<i class="el-icon-caret-bottom el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="item" v-for="(item,index) in areaCodeList"
											:key="index">{{item}}
										</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
							<div class="input">
								<input type="number" :placeholder="$t('login.inputPhone')" v-model="mobile"
									style="width: 270px;" />
							</div>
						</div>

						<div class="input_phone" v-if="curSelect==2">
							<div class="input_img">
								<img src="../../assets/login/email.png">
							</div>
							<div class="input">
								<input type="text" :placeholder="$t('login.inputEmail')" v-model="user_email"
									style="width: 320px;" />
							</div>

						</div>

						<!-- 	<div class="input_phone">
							<div class="input_img">
								<img src="../../assets/login/code.png">
							</div>
							<div class="input">
								<input type="text" :placeholder="$t('login.inputCode')" v-model="code"
									style="width: 240px;" />
							</div>
							<div class="code">
								<div class="code1" @click="getCode" v-if="!isGetCode">{{$t('login.getCode')}}</div>
								<div class="code2" v-else>{{time}}{{$t('login.sendAgain')}}</div>
							</div>
						</div> -->

						<div class="input_phone">
							<div class="input_img">
								<img src="../../assets/login/password.png">
							</div>
							<div class="input">
								<input style="width: 300px;" :type="showPassword?'text':'password'"
									:placeholder="$t('login.inputPassword')" v-model="password" />
							</div>
							<div class="is_show" @click="showPassword=!showPassword">
								<img src="../../assets/login/show.png" v-if="showPassword">
								<img src="../../assets/login/hide.png" v-else>
							</div>
						</div>
					</div>

					<div class="btn" @click="confirmClick">
						{{$t('confirm')}}
					</div>
				</main>
			</div>
			<!-- <div class="bottom">
				<loginBottom></loginBottom>
			</div> -->
		</div>

	</div>
</template>

<script>
	import loginHead from '@/components/loginHead.vue'
	import loginBottom from '@/components/loginBottom.vue'
	import {
		validate
	} from '@/common/function/validate.js';

	export default {
		components: {
			loginHead,
			loginBottom
		},
		data() {
			return {
				curSelect: 1,
				showPassword: false,

				mobile: '',
				user_email: '',
				code: '',
				password: '',
				areaCodeList: [], //区号数组
				areaCode: '', //选中的区号

				is_agree: false, //是否同意协议
				category_id: '',
				content: '', //协议内容

				time: 60,
				isGetCode: false, //是否获取验证码
				timer: '', //定时器

				isFlag: false,

				weiXinCode: '', //扫码登录成功后的code
				wxUserInfor: '', //微信用户扫码个人信息
			}
		},
		mounted() {
			this.getAreaCode();
			this.wxUserInfor = this.$route.query.wxUserInfor;
		},
		methods: {
			// 返回首页
			toHomePage() {
				this.$router.push('/home')
			},
			// 获取区号
			getAreaCode() {
				this.$http.getAreaCode().then(res => {
					if (res.code == 1) {
						this.areaCodeList = res.data;
						this.areaCode = res.data[0];
					} else {
						this.$message.error(res.msg);
					}
					console.log(res);
				}).catch((v) => {
					console.log(v);
				});
			},
			// 区号点击
			areaCodeClick(command) {
				this.areaCode = command;
			},
			// 获取验证码
			getCode() {
				if (!this.mobile && this.curSelect == 1) {
					this.$message.error(this.$t('tips.mobilePhoneNumberCannotBeEmpty'));
					return;
				}

				if (!this.user_email && this.curSelect == 2) {
					this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'));
					return;
				}

				let data = {
					type: 2,
					is_test: 0,
					mobile_code: this.areaCode,
				}
				if (this.curSelect == 1) {
					this.$set(data, "mobile", this.mobile);
				} else {
					this.$set(data, "user_email", this.user_email);
				}

				this.$http.getCode(data).then(res => {
					if (res.code == 1) {
						this.$message.success(this.$t('sendSuccess'));
						this.isGetCode = true;
						this.timer = setInterval(() => {
							if (this.time == 1) {
								clearInterval(this.timer);
								this.time = 60;
								this.isGetCode = false;
							}
							this.time--;
						}, 1000);
					} else {
						this.$message.error(res.msg);
					}
				}).catch((v) => {

				});
			},
			// 确定
			confirmClick() {
				if (this.isFlag) {
					return;
				}

				if (!this.mobile && this.curSelect == 1) {
					this.$message.error(this.$t('tips.mobilePhoneNumberCannotBeEmpty'))
					return;
				}
				if (!this.user_email && this.curSelect == 2) {
					this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'));
					return;
				}


				// if (!this.code) {
				// 	this.$message.error(this.$t('tips.codeCannotBeEmpty'))
				// 	return;
				// }

				if (!this.password) {
					this.$message.error(this.$t('tips.passwordCannotBeEmpty'))
					return;
				}


				let data = {
					// code: this.code,
					// type: 2,
					password: this.password,
					mobile_code: this.areaCode,
					unionId: this.wxUserInfor.unionid,
					nickName: this.wxUserInfor.nickname,
					gender: this.wxUserInfor.sex,
					province: this.wxUserInfor.province,
					avatarUrl: this.wxUserInfor.headimgurl,
					city: this.wxUserInfor.city,
					openId: this.wxUserInfor.openid,
				}

				if (this.curSelect == 1) {
					this.$set(data, "mobile", this.mobile);
				} else {
					this.$set(data, "user_email", this.user_email);
				}

				console.log('9999');
				console.log(data);
				this.$http.bindingAccount(data).then(res => {
					console.log(res);
					if (res.code == 1) {
						this.$message.success(res.msg);
						setTimeout(() => {
							this.isFlag = false;
							// 存储token
							localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo));
							this.$store.commit(
								"edituserinfo",
								res.data.userinfo,
							);
							this.$router.push({
								path: '/home'
							})
						}, 1500)
					} else {
						this.isFlag = false;
						this.$message.error(res.msg);
					}
				}).catch((v) => {

				});

			},
		}
	}
</script>

<style scoped="" lang="less">
	.account_login {
		width: 100%;
		height: 100vh;
		background-image: url(../../assets/login/bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.container {
		width: 1200px;
		margin: 0 auto;
	}

	.small_logo {
		cursor: pointer;
		margin-top: 42px;
		text-align: left;

		img {
			width: 162px;
			height: 44px;
		}
	}



	main {
		box-sizing: border-box;
		margin: 0 auto;
		margin-top: 80px;
		width: 672px;
		padding: 62px 126px;
		// height: 350px;
		background-color: #FFFFFF;
		box-shadow: 0px 3px 48px rgba(0, 0, 0, 0.08);

		.login_top {
			display: flex;

			.img {
				display: flex;
				align-items: center;

				img {
					width: 24px;
					height: 24px;
				}
			}

			.login_title {
				margin-left: 8px;
				font-weight: bold;
				font-size: 20px;
			}


		}

		.login_change {
			margin-top: 40px;
			display: flex;
			font-size: 20px;

			.item {
				&:hover {
					cursor: pointer;
				}

				margin-right: 64px;

				.item_title {
					font-weight: 500;
				}
			}

			.img {
				img {
					height: 11px;
				}
			}
		}

		.login_input {
			margin-top: 47px;
			font-size: 10px;

			.input_phone {
				position: relative;
				display: flex;
				align-items: center;
				background: #F6F7F9;
				border-radius: 24px;
				padding: 14px 24px;
				margin-bottom: 40px;
				font-size: 14px;

				.input_img {
					width: 20px;
					height: 20px;
					padding-right: 16px;
				}

				.input {
					padding-left: 5px;

					input {
						border: none;
						outline: none;
						background-color: transparent;
					}


					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none !important;
					}

				}
			}

			.code {
				position: absolute;
				right: 18px;
				color: #4177DD;

				.code1 {
					cursor: pointer;
				}
			}


			.is_show {
				position: absolute;
				right: 18px;


				img {
					width: 18px;
					height: 18px;
				}
			}
		}

		.btn {
			height: 48px;
			line-height: 48px;
			background: #E60012;
			opacity: 1;
			border-radius: 24px;
			font-size: 16px;
			color: #FFFFFF;

			&:hover {
				cursor: pointer;
			}
		}

		.tips {
			margin-top: 24px;
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			color: #666666;
		}
	}


	.area_code {
		&:hover {
			cursor: pointer;
		}
	}

	.el-dropdown-menu {
		// width: 100px;
		max-height: 150px !important;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	/* 定义滚动条样式 */
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		background-color: rgba(240, 240, 240, 1);
	}

	/*定义滚动条轨道 内阴影+圆角*/
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
		border-radius: 10px;
		background-color: rgba(240, 240, 240, .5);
	}

	/*定义滑块 内阴影+圆角*/

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
		background-color: rgba(240, 240, 240, .5);
	}
</style>
